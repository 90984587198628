<template>
    <b-modal :ref="modalRef" hide-footer @hidden="destroyModal" :title="'Éditer l\'étape ' + etape.nom" size="lg">
        <edit-etape-evenement :evenement="evenement" :etape="etape" :callback="callback"/>
    </b-modal>
</template>

<script>
    import modalMixin from '@/mixin/modalMixin';

    const EditEtapeEvenement = () => import('@/components/evenement/EditEtapeEvenement');

    export default {
        name: "EditEtapeModal",
        components: {EditEtapeEvenement},
        mixins: [modalMixin],
        props: {
            etape: {
                type: Object,
                required: true
            },
            callback: {
                type: Function,
                required: true
            },
            evenement: {
                type: Object,
                required: true
            }
        },
        data: () => ({
            modalRef: 'editEtapeModal'
        })
    }
</script>
